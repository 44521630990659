import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { envs } from '@shared/envs/env';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  appVersion = envs.version;
  private readonly matIconRegistry = inject(MatIconRegistry);

  ngOnInit(): void {
    this.matIconRegistry.setDefaultFontSetClass('mat-symbol-round');
  }
}
